import { ContentTypes as CONTENT_TYPES } from '@lumapps/content-types/types';
import without from 'lodash/without';

/*
 * This file contains all config based constants the client will need.
 * It is still using `module.exports` notation in order to fit with many node versions as possible since it is shared
 * with toolchain execution.
 */

// The application name.
const APPLICATION_NAME = 'LumSites';

// The module in which the `templateCache` is putted.
const TEMPLATE_CACHE_MODULE = 'AppTemplates';

/*
 * The API configuration: name of the API, version to use, ...
 */
const API_NAME = 'lumsites';
const API_VERSION = 'v1';

/*
 * Paths configuration.
 * This is made to be used in the instance script so that we are in charge of the paths and don't have to edit all
 * instance scripts each time a path changes.
 */
const ROOT_PATH = '/client/';
const BACK_OFFICE = `${ROOT_PATH}back-office/`;
const FRONT_OFFICE = `${ROOT_PATH}front-office/`;
const COMMON = `${ROOT_PATH}common/`;

const BACK_MODULES_PATH = `${BACK_OFFICE}modules/`;
const FRONT_MODULES_PATH = `${FRONT_OFFICE}modules/`;
const COMMON_MODULES_PATH = `${COMMON}modules/`;

// Indicates if we want to enable angular debug info (huge perf caveat).
const ENABLE_ANGULAR_DEBUG_INFO = false;

/*
 * The languages that we should revert first Name and last Name order.
 */
const REVERTED_FULLNAME_LANGS = ['hu', 'ja'];

/*
 * The languages that are supported and in which the product is translated.
 */
const SUPPORTED_LANGS = [
    'de',
    'en',
    'en_us',
    'es',
    'fi',
    'fr',
    'hu',
    'it',
    'ja',
    'nl',
    'pl',
    'pt',
    'pt_br',
    'pt_pt',
    'ro',
    'ru',
    'th',
    'uk',
    'vi',
    'zh',
    'zh_cn',
    'zh_tw',
];

/*
 * The default langs that we allow for contribution.
 * Note: this does NOT mean that the product is translated in those languages. Just that people can create content in
 * those languages.
 */
const DEFAULT_CONTRIBUTION_LANGS = [
    'af',
    'am',
    'ar',
    'az',
    'be',
    'bg',
    'bn',
    'bs',
    'ca',
    'ceb',
    'cs',
    'cy',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'fa',
    'fi',
    'fr',
    'fr_ca',
    'fy',
    'ga',
    'gl',
    'gu',
    'ha',
    'haw',
    'hi',
    'hmn',
    'hr',
    'hu',
    'hy',
    'id',
    'ig',
    'is',
    'it',
    'in',
    'iw',
    'ja',
    'jv',
    'ka',
    'kk',
    'km',
    'kn',
    'ko',
    'ku',
    'ky',
    'lo',
    'lt',
    'lv',
    'mg',
    'mi',
    'mk',
    'ml',
    'mn',
    'mr',
    'ms',
    'mt',
    'my',
    'ne',
    'nl',
    'no',
    'ny',
    'or',
    'pa',
    'pl',
    'ps',
    'pt',
    'pt_br',
    'ro',
    'ru',
    'rw',
    'sd',
    'si',
    'sk',
    'sl',
    'sm',
    'sn',
    'so',
    'sq',
    'sr',
    'st',
    'su',
    'sv',
    'sw',
    'ta',
    'te',
    'tg',
    'th',
    'tk',
    'tl',
    'tr',
    'tt',
    'ug',
    'uk',
    'ur',
    'uz',
    'vi',
    'xh',
    'yi',
    'yo',
    'zh',
    'zh_hk',
    'zh_tw',
    'zu',
];

// Indicates if we want to automatically enqueue XHR request to a "list" endpoint.
const ENABLE_LIST_XHR_QUEUE = true;
// The maximum XHR requests that can run in parallel.
const MAX_XHR_QUEUE_SIZE = 4;
// The maximum time for a request to be delayed by the queue (in seconds).
const MAX_XHR_QUEUE_DELAY = 10;

/*
 * Contains the elements that need to have scope information attached to the their DOM.
 * The keys of the object is the type of the element:
 *     - footer: the footer must have scope info attached to its `<footer>` element;
 *     - widgets: any widget must have have scope info attached to its element;
 *
 * The value is either a selector or an object of selectors (with the keys beeing an id of the element we want to
 * target).
 */
const SCOPE_INFO = {};

/*
 * Indicates if you want to allow to write metrics in stackdriver for your local dev environment.
 * Note that these metrics will be written to the `lumsites-dev` stackdriver project and will all be suffixed by
 * `_local` so that you can distinguish them from those of the online dev environment.
 */
const ALLOW_METRIC_IN_LOCAL = false;

/**
 * The default list of widget types (migrated from the lumapps-back repo `app/server/widget/__init__.py`).
 */
window.WIDGET_TYPES = {
    AWESOME_TABLE: 'awesome-table',
    BREADCRUMB: 'breadcrumb',
    CALENDAR: 'calendar',
    CHANNEL_LIST: 'channel-list',
    CHAT: 'chat',
    COMMENTS: 'comments',
    COMMUNITY_LIST: 'community-list',
    COMMUNITY_MEMBERS: 'community-members',
    COMMUNITY_NAVIGATION: 'community-navigation',
    COMMUNITY_POST_LIST: 'community-post-list',
    COMMUNITY_INTRO: 'community-intro',
    CONTACT: 'contact',
    CONTENT_FILTER: 'content-filter',
    CONTENT_LIST: 'content-list',
    DALKIA_DRIVE: 'dalkia-drive',
    DELVE: 'delve',
    DIRECTORY_ENTRY: 'directory-entry',
    DRAWIO: 'drawio',
    DRIVE: 'drive',
    DRIVE_FILE_PREVIEW: 'drive-file-preview',
    EMAIL: 'email',
    FEATURED_IMAGE: 'featured-image',
    FILE_LIST: 'file-list',
    FILE_MANAGEMENT: 'file-management',
    HTML: 'html',
    IFRAME: 'iframe',
    IMAGE_GALLERY: 'image-gallery',
    INTRO: 'intro',
    TASKS: 'tasks',
    INSTANCE_LIST: 'instance-list',
    LEARNING_LEADERBOARD: 'learning-leaderboard',
    LINKS: 'links',
    MANDATORY_READ: 'mandatory-read',
    MEDIA: 'media',
    META: 'meta',
    METADATA: 'metadata',
    META_SOCIAL: 'meta-social',
    PLAY: 'play',
    PLAYLIST: 'playlist',
    POST_DETAILS: 'post-details',
    CHART: 'chart',
    PROCESS_MAKER: 'process-maker',
    REMOTE: 'remote',
    RSS: 'rss',
    SHARE: 'share',
    SHAREPOINT_SITE_LIST: 'sharepoint-site-list',
    STANDALONE_QUESTION: 'standalone-question',
    STOCK_EXCHANGE: 'stock-exchange',
    SUB_NAV: 'sub-nav',
    SUMMARY: 'summary',
    TIP: 'tip',
    TITLE: 'title',
    TODO: 'todo',
    USER: 'user',
    VIDEO: 'video',
    WALL: 'wall',
    WEATHER: 'weather',
    EVENT_LIST: 'event-list',
    CONTRIBUTION: 'contribution',
    ARTICLE_LIST: 'article-list',
    PERSONAL_FEED_PREVIEW: 'personal-feed-preview',
    RECOMMENDED_TRAINING_COURSES: 'learning-recommended-training-courses',
    TRAINING_COURSES_IN_PROGRESS: 'learning-training-courses-in-progress',
    LEARNING_CATALOG: 'learning-catalog',
    LEARNING_CERTIFICATE: 'learning-certificate',
    USER_KPI: 'learning-user-kpi',
    TEAM_KPI: 'learning-team-kpi',
    MICRO_APP: 'micro-app',
};

/*
 * Contains the list of all default widgets that should be available in the designer, no matter what.
 * This list is based on the Widget Types and Content Types that the backend spits in the index.html through Jinja.
 */
const DEFAULT_WIDGETS = [
    {
        googleSync: false,
        icon: 'map-marker',
        type: WIDGET_TYPES.BREADCRUMB,
    },
    {
        googleSync: false,
        icon: 'calendar',
        type: WIDGET_TYPES.CALENDAR,
    },
    {
        googleSync: false,
        icon: 'comment-multiple-outline',
        type: WIDGET_TYPES.COMMENTS,
    },
    {
        googleSync: false,
        icon: 'filter',
        type: WIDGET_TYPES.CONTENT_FILTER,
    },
    {
        googleSync: false,
        icon: 'library-books',
        type: WIDGET_TYPES.CONTENT_LIST,
    },
    {
        googleSync: false,
        icon: 'email',
        type: WIDGET_TYPES.CONTACT,
    },
    {
        googleSync: false,
        icon: 'book-multiple',
        type: WIDGET_TYPES.DIRECTORY_ENTRY,
    },
    {
        googleSync: false,
        icon: 'email',
        type: WIDGET_TYPES.EMAIL,
    },
    {
        googleSync: false,
        icon: 'image',
        type: WIDGET_TYPES.FEATURED_IMAGE,
    },
    {
        googleSync: false,
        icon: 'xml',
        type: WIDGET_TYPES.HTML,
    },
    {
        googleSync: false,
        icon: 'crop-free',
        type: WIDGET_TYPES.IFRAME,
    },
    {
        googleSync: false,
        icon: 'image-multiple',
        type: WIDGET_TYPES.IMAGE_GALLERY,
    },
    {
        googleSync: false,
        icon: 'view-headline',
        type: WIDGET_TYPES.INTRO,
    },
    {
        googleSync: false,
        icon: 'link',
        type: WIDGET_TYPES.LINKS,
    },
    {
        googleSync: false,
        icon: 'file-check',
        type: WIDGET_TYPES.MANDATORY_READ,
    },
    {
        googleSync: false,
        icon: 'timetable',
        type: WIDGET_TYPES.META,
    },
    {
        googleSync: false,
        icon: 'dns',
        onlyForTemplate: true,
        type: WIDGET_TYPES.METADATA,
    },
    {
        googleSync: false,
        icon: 'chart-bar',
        isMicrosoft: true,
        type: WIDGET_TYPES.CHART,
    },
    {
        googleSync: false,
        icon: 'share-variant',
        type: WIDGET_TYPES.SHARE,
    },
    {
        googleSync: false,
        icon: 'sitemap',
        isMicrosoft: true,
        type: WIDGET_TYPES.SHAREPOINT_SITE_LIST,
    },
    {
        googleSync: false,
        icon: 'menu',
        type: WIDGET_TYPES.SUB_NAV,
    },
    {
        googleSync: false,
        icon: 'format-list-checks',
        type: WIDGET_TYPES.TASKS,
    },
    {
        googleSync: false,
        icon: 'help-circle',
        type: WIDGET_TYPES.TIP,
    },
    {
        googleSync: false,
        icon: 'format-title',
        type: WIDGET_TYPES.TITLE,
    },
    {
        googleSync: false,
        icon: 'account-multiple',
        type: WIDGET_TYPES.USER,
    },
    {
        googleSync: false,
        icon: 'video',
        type: WIDGET_TYPES.VIDEO,
    },
    {
        googleSync: false,
        icon: 'comment-question-outline',
        type: WIDGET_TYPES.STANDALONE_QUESTION,
    },
    {
        context: [CONTENT_TYPES.COMMUNITY],
        googleSync: false,
        icon: 'account',
        type: WIDGET_TYPES.COMMUNITY_MEMBERS,
    },
    {
        context: [CONTENT_TYPES.COMMUNITY],
        googleSync: false,
        icon: 'view-headline',
        type: WIDGET_TYPES.COMMUNITY_INTRO,
    },
];

/**
 * Array of all NGI-compatible widgets.
 * This list aims to cover all widgets that we consider as fully compatible for NGI.
 * It is not linked to the backend as some widgets are partially compatible and we don't
 * want to make them available in the frontend even though the backend says the opposite.
 */
const AVAILABLE_NGI_WIDGETS = [
    WIDGET_TYPES.ARTICLE_LIST,
    WIDGET_TYPES.BREADCRUMB,
    WIDGET_TYPES.COMMENTS,
    WIDGET_TYPES.COMMUNITY_LIST,
    WIDGET_TYPES.COMMUNITY_POST_LIST,
    WIDGET_TYPES.CONTACT,
    WIDGET_TYPES.CONTENT_LIST,
    WIDGET_TYPES.CONTRIBUTION,
    WIDGET_TYPES.DIRECTORY_ENTRY,
    WIDGET_TYPES.EVENT_LIST,
    WIDGET_TYPES.LEARNING_CATALOG,
    WIDGET_TYPES.LEARNING_CERTIFICATE,
    WIDGET_TYPES.FEATURED_IMAGE,
    WIDGET_TYPES.HTML,
    WIDGET_TYPES.IFRAME,
    WIDGET_TYPES.IMAGE_GALLERY,
    WIDGET_TYPES.INSTANCE_LIST,
    WIDGET_TYPES.INTRO,
    WIDGET_TYPES.LINKS,
    WIDGET_TYPES.META,
    WIDGET_TYPES.META_SOCIAL,
    WIDGET_TYPES.METADATA,
    WIDGET_TYPES.MICRO_APP,
    WIDGET_TYPES.PERSONAL_FEED_PREVIEW,
    WIDGET_TYPES.PLAY,
    WIDGET_TYPES.PLAYLIST,
    WIDGET_TYPES.POST_DETAILS,
    WIDGET_TYPES.REMOTE,
    WIDGET_TYPES.SHARE,
    WIDGET_TYPES.TITLE,
    WIDGET_TYPES.USER,
    WIDGET_TYPES.VIDEO,
    WIDGET_TYPES.RECOMMENDED_TRAINING_COURSES,
    WIDGET_TYPES.TRAINING_COURSES_IN_PROGRESS,
    WIDGET_TYPES.USER_KPI,
    WIDGET_TYPES.TEAM_KPI,
    WIDGET_TYPES.LEARNING_LEADERBOARD,
    WIDGET_TYPES.SUMMARY,
    WIDGET_TYPES.STANDALONE_QUESTION,
];

const AVAILABLE_SPACE_WIDGETS = without(
    AVAILABLE_NGI_WIDGETS,
    WIDGET_TYPES.BREADCRUMB,
    WIDGET_TYPES.COMMENTS,
    WIDGET_TYPES.META,
    WIDGET_TYPES.METADATA,
    WIDGET_TYPES.META_SOCIAL,
    WIDGET_TYPES.CONTRIBUTION,
    WIDGET_TYPES.PERSONAL_FEED_PREVIEW,
    WIDGET_TYPES.MANDATORY_READ,
    WIDGET_TYPES.SUMMARY,
);

const COMMUNITY_WIDGETS_EXCLUSION_LIST = [
    WIDGET_TYPES.CONTRIBUTION,
    WIDGET_TYPES.PERSONAL_FEED_PREVIEW,
    WIDGET_TYPES.MANDATORY_READ,
];

export {
    ALLOW_METRIC_IN_LOCAL,
    API_NAME,
    API_VERSION,
    APPLICATION_NAME,
    AVAILABLE_NGI_WIDGETS,
    AVAILABLE_SPACE_WIDGETS,
    COMMUNITY_WIDGETS_EXCLUSION_LIST,
    BACK_MODULES_PATH,
    COMMON_MODULES_PATH,
    DEFAULT_CONTRIBUTION_LANGS,
    DEFAULT_WIDGETS,
    ENABLE_ANGULAR_DEBUG_INFO,
    ENABLE_LIST_XHR_QUEUE,
    FRONT_MODULES_PATH,
    FRONT_OFFICE,
    MAX_XHR_QUEUE_DELAY,
    MAX_XHR_QUEUE_SIZE,
    REVERTED_FULLNAME_LANGS,
    ROOT_PATH,
    SCOPE_INFO,
    SUPPORTED_LANGS,
    TEMPLATE_CACHE_MODULE,
};
